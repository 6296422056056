<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">退车结算</div>
				<div class="header-button">
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/driver-center/order-settlement/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					showSelection
					autoWidth
				>
					<template #terminateContractType="{ value }">
						<span>{{ mapDict('terminate_contract_type', value) }}</span>
					</template>
					<template #status="{ value }">
						<span>{{ mapDict('settlement_status', value) }}</span>
					</template>
					<template #contractEndDate="{ value }">
						<span>{{ convertDate(value) }}</span>
					</template>
					<template #returnCarDate="{ value }">
						<span>{{ convertDate(value) }}</span>
					</template>
					<template #operation="{ row }">
						<span class="click" @click="onView(row)">查看</span>
						<span class="click" @click="onReview(row)" v-if="row.status == 4">复核</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<print-form ref="PrintRef" />
		<settle-form ref="SettleRef" @on-detail="onStockInDetail" @on-refresh="fetchList" />
		<StockInDetail ref="stockInRef" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'SettleCar',
})
</script>

<script setup name="SettleCar">
import dayjs from 'dayjs'
import { inject, ref } from 'vue'
import { useRoute } from 'vue-router'
import { setting, columns } from './config'
import { useState, useFetch, useDict, useFilter } from '@/utils/hooks.js'
import { companySelect, maintenanceSelect } from '@/api/vehicle.js'
import { settlementBillList, exportSettlementBill } from '@/api/driver.js'
import { getStore } from '@/utils/store'
import { handleExport } from '@/utils/util'
import StockInDetail from '../../vehicle/check/form'
import PrintForm from './print'
import SettleForm from './form'

const $message = inject('$message')

const PrintRef = ref(null)
const SettleRef = ref(null)
const stockInRef = ref(null)
const settingRef = ref(setting)
const userInfo = getStore({ name: 'userInfo' })

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const [loading, setLoading] = useState(false)
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [page, setPage] = useState({ current: 1, size: 20 })

async function fetchList() {
	const payload = { ...params.value, page: page.value }
	const service = settlementBillList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setTable(data.records)
		setTotal(data.total)
	}
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
function convertDate(val, type) {
	if (type === 'month') return dayjs(val).format('YYYY-MM')
	return dayjs(val).format('YYYY-MM-DD')
}

async function onExport() {
	setLoading(true)
	try {
		const res = await exportSettlementBill(params.value)
		if (res) handleExport(res, '退车结算')
	} catch (err) {
		$message.error(err.msg || err.message)
	} finally {
		setLoading(false)
	}
}
function onView(row) {
	PrintRef.value.open(row)
}
function onReview(row) {
	SettleRef.value.open(row.id)
}
function onStockInDetail(id) {
	console.log('ref', id, stockInRef)
	stockInRef.value.open(id, 'detail')
}
async function fetchSelect() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const [driverServiceRes, compRes] = await Promise.all([
		useFilter(() => maintenanceSelect('DriverService', arr, { isAll: true }), {
			name: 'realName',
			code: 'id',
		}),
		useFilter(() => companySelect(true), { name: 'companyName', code: 'companyId' }),
	])
	if (!driverServiceRes.err) {
		const driverSupport = findItem('driverSupportId')
		driverSupport.options = driverServiceRes.data
	}
	if (!compRes.err) {
		const company = findItem('companyIds')
		company.options = compRes.data
	}
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}
function onChangeFilter(event) {
	setParams(event)
	fetchList()
}

async function fetchDict() {
	const dict = await useDict(['settlement_status', 'terminate_contract_type'])
	if (dict) setDict(dict)
}
function init() {
	fetchDict()
	fetchList()
	fetchSelect()
	const route = useRoute()
	const query = route.query
	// if (Object.keys(query).length > 0) {
	// 	params.value.keyword = query.keyword || ''
	// 	params.value.status = query.status || null
	// 	params.value.companyId = query?.companyIds?.split(',') || []
	// 	const [start, end] = query?.statementDate?.split(',') || []
	// 	params.value.statementDate =
	// 		(query?.statementDate && [`${start} 00:00:00`, `${end ? end : start} 23:59:59`]) || null
	// 	const ins = getCurrentInstance()
	// 	ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params.value)
	// }
}
init()
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
