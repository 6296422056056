<template>
	<el-dialog
		v-model="visible"
		width="1000px"
		class="container"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div id="print">
			<p style="text-align: center; font-size: 24px; color: rgba(0, 0, 0, 0.84)">
				{{ settleNameComp }}
			</p>
			<div class="header">
				<div>
					<span style="margin-right: 12px">退车验车单：{{ model.carInspectTime }}</span>
					<span style="margin-right: 12px">质损金额：{{ model.damageAmount }}</span>
					<span>验车人：{{ model.carInspectUser }}</span>
				</div>
			</div>
			<div class="table">
				<table border="1" cellspacing="0" cellpadding="5" style="width: 940px">
					<tr>
						<td>承租人</td>
						<td>车牌号</td>
						<td>车型</td>
						<td>退车类型</td>
						<td>租期（月）</td>
						<td>租赁方式</td>
					</tr>
					<tr>
						<td>{{ model.lessee }}</td>
						<td>{{ model.licensePlateNum }}</td>
						<td style="min-height: 40px">{{ model.carModelName }}</td>
						<td>{{ mapDict('terminate_contract_type', model.terminateContractType) }}</td>
						<td>{{ model.contractPeriod }}</td>
						<td>{{ model.leaseType }}</td>
					</tr>
					<tr>
						<td>合同起租日期</td>
						<td>实际还车日期</td>
						<td>实际使用月份</td>
						<td>不满一个月天数</td>
						<td>合同月租金（元）</td>
						<td>行驶证-营运证</td>
					</tr>
					<tr>
						<td>{{ dayjs(model.contractStartDate).format('YYYY-MM-DD') }}</td>
						<td>{{ dayjs(model.returnCarDate).format('YYYY-MM-DD') }}</td>
						<td>{{ model.leaseMonth }}</td>
						<td>{{ model.leaseDays }}</td>
						<td>{{ model.monthlyRent }}</td>
						<td>{{ model.certificateCondition }}</td>
					</tr>
					<tr>
						<td colspan="2">租金费用</td>
						<td colspan="2">车辆费用</td>
						<td colspan="2">其他费用</td>
					</tr>
					<tr>
						<td>期满应收租金</td>
						<td>{{ model.expirationRentReceivable }} 元</td>
						<td>事故加速折旧费</td>
						<td>{{ model.accidentDepreciationFee }} 元</td>
						<td>停车费/换电费</td>
						<td>{{ model.parkingFee }} 元</td>
					</tr>
					<tr>
						<td>实际应收租金</td>
						<td>{{ model.actualRentReceivable }} 元</td>
						<td>绝对免赔</td>
						<td>{{ model.absoluteDeductibleFee }} 元</td>
						<td>收车费</td>
						<td>{{ model.vehicleRecyclingFee }} 元</td>
					</tr>
					<tr>
						<td>代扣租金（已支付）</td>
						<td>{{ model.actualRentReceived }} 元</td>
						<td>
							事故费用（出险次数:
							<span>{{ model.vehicleInsuranceClaimCount }}）</span>
						</td>
						<td>{{ model.accidentFee }} 元</td>
						<td>
							<p style="margin: 0">违章</p>
							(分数{{ model.undisposedDeductionPoint }} 罚款{{ model.undisposedFinesAmount }})
						</td>
						<td>{{ model.violateRegulationDisposeFee }} 元</td>
					</tr>
					<tr>
						<td>线下已收+平台锁定</td>
						<td>{{ model.offlinePaidRent }} 元</td>
						<td>内饰清洗费</td>
						<td>{{ model.interiorCleaningFee }} 元</td>
						<td>车钥匙+GPS</td>
						<td>{{ model.gpsFee }} 元</td>
					</tr>
					<tr>
						<td>应补租金</td>
						<td>{{ model.supplementalRent }} 元</td>
						<td>车损</td>
						<td>{{ model.vehicleDamageFee }} 元</td>
						<td>营运证</td>
						<td>{{ model.operationLicenseFee }} 元</td>
					</tr>
					<tr>
						<td>违约金</td>
						<td>{{ model.breachPenalty }} 元</td>
						<td>加速折旧费</td>
						<td>{{ model.depreciationFee }} 元</td>
						<td>行驶证</td>
						<td>{{ model.drivingLicenseFee }} 元</td>
					</tr>
					<tr>
						<td>小计</td>
						<td>{{ model.totalRentFee }} 元</td>
						<td>小计</td>
						<td>{{ model.totalCarFee }} 元</td>
						<td>小计</td>
						<td>{{ model.totalOtherFee }} 元</td>
					</tr>
					<tr>
						<td colspan="2">费用合计</td>
						<td colspan="2">{{ model.totalFee }} 元</td>
						<td>营销方案折算现金</td>
						<td>签字</td>
					</tr>
					<tr>
						<td rowspan="4">营销方案</td>
						<td rowspan="4">{{ model.marketingPlanAmount }} 元</td>
						<td colspan="2">
							{{ model.marketingPlan[0].content }}
						</td>
						<td>
							{{ model.marketingPlan[0].amount ? model.marketingPlan[0].amount + ' 元' : '' }}
						</td>
						<td rowspan="4"></td>
					</tr>
					<tr>
						<td colspan="2">{{ model.marketingPlan[1].content }}</td>
						<td>
							{{ model.marketingPlan[1].amount ? model.marketingPlan[1].amount + ' 元' : '' }}
						</td>
					</tr>
					<tr>
						<td colspan="2">{{ model.marketingPlan[2].content }}</td>
						<td>
							{{ model.marketingPlan[2].amount ? model.marketingPlan[2].amount + ' 元' : '' }}
						</td>
					</tr>
					<tr>
						<td colspan="2">{{ model.marketingPlan[3].content }}</td>
						<td>
							{{ model.marketingPlan[3].amount ? model.marketingPlan[3].amount + ' 元' : '' }}
						</td>
					</tr>
					<tr>
						<td colspan="2">押金金额</td>
						<td colspan="2">{{ model.actualDeposit }} 元</td>
						<td>退车补款</td>
						<td>{{ model.supplementalAmount }} 元</td>
					</tr>
					<tr>
						<td>应退押金</td>
						<td colspan="2">{{ model.dueDepositRefund }} 元</td>
						<td colspan="2">实退押金</td>
						<td>{{ model.depositRefund }} 元</td>
					</tr>
					<tr>
						<td>司机签字</td>
						<td>司管签字</td>
						<td>车务签字</td>
						<td>运营经理签字</td>
						<td>财务签字</td>
						<td>实际退款日期</td>
					</tr>
					<tr>
						<td style="height: 60px"></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</table>
			</div>
		</div>

		<div v-if="model.unpaidBillList && model.unpaidBillList.length">
			<div style="background: #f2f2f2; line-height: 30px; padding: 0 20px; margin: 10px 0">
				结算通过后，线下已收：
				<span style="color: #ed9f45">{{ model.offlinePaidRent }}</span>
				元，押金金额：
				<span style="color: #ed9f45">{{ paidDeposit }}</span>
				元 将会抵扣未支付租金账单，线下已收可抵扣：
				<span style="color: #e30f04">
					{{ offlineRemain }}
				</span>
				元，押金金额可抵扣：
				<span style="color: #e30f04">{{ depositRemain }}</span>
				元
			</div>
			<yi-table
				ref="yiTableRef"
				table-height="100%"
				:columns="columns"
				:data="model.unpaidBillList"
				autoWidth
			>
				<template #actualTradeAmount="{ row, value }">
					<p style="margin: 0 !important; line-height: 16px">{{ value }}</p>
					<span style="font-size: 12px" v-if="row.deductionAmount">
						减免抵扣{{ row.deductionAmount }}
					</span>
					<span v-if="row.deductionAmount && row.deductionDays">,</span>
					<span style="font-size: 12px" v-if="row.deductionDays">
						租期未满减{{ row.deductionDays }}天
					</span>
				</template>
			</yi-table>
		</div>

		<div v-if="model.supplementalRentBills">
			<div style="background: #f2f2f2; line-height: 30px; padding: 0 20px; margin: 10px 0">
				押金用于抵扣应补租金
				<span style="color: #ed9f45">
					{{ depositRemain }}
				</span>
				元，抵扣完剩余押金 ：
				<span style="color: #e30f04">
					{{ supplementalRemain }}
				</span>
				元
			</div>
			<yi-table
				ref="yiTableRef"
				table-height="100%"
				:columns="supplementColumn"
				:data="[model.supplementalRentBills]"
				autoWidth
			></yi-table>
		</div>

		<div v-if="model.settlementRemark">
			<p>退车结算说明：</p>
			<el-input
				v-model="model.settlementRemark"
				type="textarea"
				:autosize="{ minRows: 4 }"
				disabled
				show-word-limit
			/>
		</div>
		<p v-if="model.settlementAnnexesArr">退车结算附件</p>
		<yi-file-uploader
			v-model="model.settlementAnnexesArr"
			type="file"
			disabled
			source-type="image"
			product-type="renrenjia"
			accept=".jpg,.jpeg,.png,.xlsx,.xls,.pdf,.zip,.rar,.pptx,.docx"
			:max="15"
			:uploader-size="15"
		/>

		<template #footer>
			<div class="dialog-footer">
				<el-button :loading="loading" type="primary" @click="onPrint">打印</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, inject, computed, ref } from 'vue'
import convert from 'dom-to-image'
import { useState, useModal, useFetch, useDict } from '@/utils/hooks.js'
import { querySettlementBill } from '@/api/driver.js'

const $message = inject('$message')

const columns = [
	{
		prop: 'tradeBillName',
		label: '账单名称',
	},
	{
		prop: 'statementMonth',
		label: '账单月',
	},
	{
		prop: 'actualTradeAmount',
		label: '应收额',
	},
	{
		prop: 'actualAmount',
		label: '已实收额',
	},
	{
		prop: 'dueAmount',
		label: '欠租金额',
	},
	{
		prop: 'depositDeductionAmount',
		label: '押金抵扣',
	},
]

const supplementColumn = [
	{
		prop: 'tradeBillName',
		label: '账单名称',
	},
	{
		prop: 'statementMonth',
		label: '账单月',
	},
	{
		prop: 'tradeAmount',
		label: '应收额',
	},
	{
		prop: 'depositDeductionAmount',
		label: '押金抵扣',
	},
]

const { visible, setVisible } = useModal()
const [model, setModel] = useState({ marketingPlan: Array.from({ length: 4 }, _ => ({})) })
const [dicts, setDicts] = useState({})
const [loading, setLoading] = useState(false)
const { fetchData } = useFetch()
const supplementalRemain = ref(0)

const paidDeposit = computed(() => {
	const { actualDeposit } = model.value
	return actualDeposit * 1
})

const remainAmount = ref(0)
const depositRemain = computed(() => {
	return paidDeposit.value
})
const offlineRemain = computed(() => {
	return model.value.offlinePaidRent * 1 - calcSum() * 1 <= 0
		? 0
		: (model.value.offlinePaidRent * 1 - calcSum() * 1).toFixed(2)
})
const settleNameComp = computed(() => {
	const { companyName } = model.value
	return `${companyName || ''}退车结算单`
})

function onSupRemainChange() {
	const num = depositRemain.value * 1 - model.value.supplementalRentBills.depositDeductionAmount * 1
	if (num > 0) {
		supplementalRemain.value = num.toFixed(2)
	} else {
		supplementalRemain.value = 0
	}
}
async function getDict() {
	const dict = await useDict('terminate_contract_type')
	setDicts(dict)
}
function mapDict(key, value) {
	const e = dicts.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}

function open(data) {
	getDict()
	setVisible(true)
	getDetail(data.id)
}

function onClose() {
	setVisible(false)
	setModel({ marketingPlan: Array.from({ length: 4 }, _ => ({})) })
}
const calcSum = () => {
	const { unpaidBillList } = model.value
	const total = unpaidBillList.reduce((acc, cur) => {
		if (!isNaN(cur.depositDeductionAmount)) {
			acc += cur.depositDeductionAmount * 1
		}
		return acc
	}, 0)
	return total
}
async function getDetail(id) {
	const { err, data } = await fetchData(querySettlementBill, id)
	if (err) return $message.error(err.message)
	console.log('结算单数据', data)
	const { marketingPlan = [] } = data
	const len = marketingPlan ? marketingPlan.length : 0
	const length = 4 - len
	setModel({
		...data,
		marketingPlan: (data.marketingPlan || []).concat(Array.from({ length }, _ => ({}))),
	})
	const { unpaidBillList } = model.value
	if (unpaidBillList) {
		const res = model.value.offlinePaidRent * 1 + paidDeposit.value * 1 - calcSum()
		remainAmount.value = res.toFixed(2)
	}
	onSupRemainChange()
}
async function onPrint() {
	setLoading(true)
	const canvas = document.getElementById('print')
	const url = await convert.toPng(canvas, { bgcolor: '#fff' })
	const downloadLink = document.createElement('a')
	downloadLink.href = url
	const { lessee, licensePlateNum } = model.value
	downloadLink.download = `湖北人人驾退车结算单-${lessee}-${licensePlateNum}.jpg`
	downloadLink.click()
	setLoading(false)
	onClose()
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.header {
	.flexible(row, center, space-between);
	margin-bottom: 12px;
	color: rgba(0, 0, 0, 0.84);
	font-weight: 500;
	.click {
		color: #409eff;
		cursor: pointer;
	}
}
#print {
	padding: 0 10px 30px 10px;
}
.yi-table {
	border: 1px solid #000;
}
table {
	border-collapse: collapse;
}
th,
td {
	border: 1px solid rgba(0, 0, 0, 0.84);
	text-align: center;
	min-width: 120px;
	height: 36px;
}
tr {
	text-align: center;
}
</style>
