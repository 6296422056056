<template>
	<el-dialog
		v-model="visible"
		width="1000px"
		class="container"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div id="print">
			<p style="text-align: center; font-size: 24px; color: rgba(0, 0, 0, 0.84)">
				{{ settleNameComp }}
			</p>
			<div class="header">
				<div>
					<span style="margin-right: 12px">退车验车单：{{ model.carInspectTime }}</span>
					<span style="margin-right: 12px">质损金额：{{ model.damageAmount }}</span>
					<span>验车人：{{ model.carInspectUser }}</span>
				</div>
				<span class="click" @click="onDetail">查看验车单</span>
			</div>
			<div class="table" v-loading="tableLoading">
				<el-form :model="model">
					<table border="1" cellspacing="0" cellpadding="5">
						<tr>
							<td style="width: 150px">承租人</td>
							<td style="width: 130px">车牌号</td>
							<td>车型</td>
							<td style="width: 130px">退车类型</td>
							<td style="width: 140px">租期（月）</td>
							<td style="width: 130px">租赁方式</td>
						</tr>
						<tr>
							<td>{{ model.lessee }}</td>
							<td>{{ model.licensePlateNum }}</td>
							<td>{{ model.carModelName }}</td>
							<td>{{ mapDict('terminate_contract_type', model.terminateContractType) }}</td>
							<td>{{ model.contractPeriod }}</td>
							<td>{{ model.leaseType }}</td>
						</tr>
						<tr>
							<td>合同起租日期</td>
							<td>实际还车日期</td>
							<td>实际使用月份</td>
							<td>不满一个月天数</td>
							<td>合同月租金（元）</td>
							<td>行驶证-营运证</td>
						</tr>
						<tr>
							<td>{{ dayjs(model.contractStartDate).format('YYYY-MM-DD') }}</td>
							<td>
								{{ dayjs(model.returnCarDate).format('YYYY-MM-DD') }}
							</td>
							<td>{{ model.leaseMonth }}</td>
							<td>{{ model.leaseDays || 0 }}</td>
							<td>{{ model.monthlyRent }}</td>
							<td>
								<el-select placeholder="请选择" v-model="model.certificateCondition">
									<el-option label="有" value="有"></el-option>
									<el-option label="无" value="无"></el-option>
								</el-select>
							</td>
						</tr>
						<tr>
							<td colspan="2">租金费用</td>
							<td colspan="2">车辆费用</td>
							<td colspan="2">其他费用</td>
						</tr>
						<tr>
							<td>期满应收租金</td>
							<td>{{ model.expirationRentReceivable || '0.00' }} 元</td>
							<td>事故加速折旧费</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.accidentDepreciationFee" />
									元
								</div>
							</td>
							<td>停车费/换电费</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.parkingFee" />
									元
								</div>
							</td>
						</tr>
						<tr>
							<td>实际应收租金</td>
							<td>
								<div class="flex-row">
									<el-input
										class="mr6"
										maxLength="10"
										v-model="model.actualRentReceivable"
										@change="onActualRentChange"
									/>
									元
								</div>
							</td>
							<td>绝对免赔</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.absoluteDeductibleFee" />
									元
								</div>
							</td>
							<td>收车费</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.vehicleRecyclingFee" />
									元
								</div>
							</td>
						</tr>
						<tr>
							<td>代扣租金（已支付）</td>
							<td>{{ model.actualRentReceived || '0.00' }} 元</td>
							<td>
								<p style="padding: 0; margin: 0">事故费用</p>
								(出险次数：
								<el-input
									maxLength="10"
									v-model="model.vehicleInsuranceClaimCount"
									style="width: 30px"
								/>
								)
							</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.accidentFee" />
									元
								</div>
							</td>
							<td>
								<p style="padding: 0; margin: 0">违章</p>
								(分数{{ model.undisposedDeductionPoint }} 罚款{{ model.undisposedFinesAmount }})
							</td>
							<td>
								<div class="flex-row">
									<el-input
										class="mr6"
										maxLength="10"
										v-model="model.violateRegulationDisposeFee"
									/>
									元
								</div>
							</td>
						</tr>
						<tr>
							<td>线下已收+平台锁定</td>
							<td>
								<div class="flex-row">
									<el-input
										class="mr6"
										maxLength="10"
										v-model="model.offlinePaidRent"
										@change="onSumChange"
									/>
									元
								</div>
							</td>
							<td>内饰清洗费</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.interiorCleaningFee" />
									元
								</div>
							</td>
							<td>车钥匙+GPS</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.gpsFee" />
									元
								</div>
							</td>
						</tr>
						<tr>
							<td>应补租金</td>
							<td>{{ supplementalRent || '0.00' }} 元</td>
							<td>车损</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.vehicleDamageFee" />
									元
								</div>
							</td>
							<td>营运证</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.operationLicenseFee" />
									元
								</div>
							</td>
						</tr>
						<tr>
							<td>违约金</td>
							<td>{{ model.breachPenalty || '0.00' }} 元</td>
							<td>加速折旧费</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.depreciationFee" />
									元
								</div>
							</td>
							<td>行驶证</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.drivingLicenseFee" />
									元
								</div>
							</td>
						</tr>
						<tr>
							<td>小计</td>
							<td>{{ 小计1 }} 元</td>
							<td>小计</td>
							<td>{{ 小计2 }} 元</td>
							<td>小计</td>
							<td>{{ 小计3 }} 元</td>
						</tr>
						<tr>
							<td colspan="2">费用合计</td>
							<td colspan="2">{{ totalFee }} 元</td>
							<td>营销方案折算现金</td>
							<td>签字</td>
						</tr>
						<tr>
							<td rowspan="4">营销方案</td>
							<td rowspan="4">{{ marketingPlanAmount }}</td>
							<td colspan="2">
								<el-input
									v-model="model.marketingPlan[0].content"
									placeholder="在此填写营销折算内容"
									:disabled="model.marketingPlan[0].disabled"
								/>
							</td>
							<td>
								<div class="flex-row">
									<el-input
										class="mr6"
										maxLength="10"
										v-model="model.marketingPlan[0].amount"
										:disabled="model.marketingPlan[0].disabled"
									/>
									元
								</div>
							</td>
							<td rowspan="4"></td>
						</tr>
						<tr>
							<td colspan="2">
								<el-input
									v-model="model.marketingPlan[1].content"
									placeholder="在此填写营销折算内容"
									:disabled="model.marketingPlan[1].disabled"
								/>
							</td>
							<td>
								<div class="flex-row">
									<el-input
										class="mr6"
										maxLength="10"
										v-model="model.marketingPlan[1].amount"
										:disabled="model.marketingPlan[1].disabled"
									/>
									元
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<el-input
									v-model="model.marketingPlan[2].content"
									placeholder="在此填写营销折算内容"
									:disabled="model.marketingPlan[2].disabled"
								/>
							</td>
							<td>
								<div class="flex-row">
									<el-input
										class="mr6"
										maxLength="10"
										v-model="model.marketingPlan[2].amount"
										:disabled="model.marketingPlan[2].disabled"
									/>
									元
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">
								<el-input
									v-model="model.marketingPlan[3].content"
									:disabled="model.marketingPlan[3].disabled"
									placeholder="在此填写营销折算内容"
								/>
							</td>
							<td>
								<div class="flex-row">
									<el-input
										class="mr6"
										maxLength="10"
										v-model="model.marketingPlan[3].amount"
										:disabled="model.marketingPlan[3].disabled"
									/>
									元
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2">押金金额</td>
							<td colspan="2">{{ paidDeposit }} 元</td>
							<td>退车补款</td>
							<td>
								<div class="flex-row">
									<el-input class="mr6" maxLength="10" v-model="model.supplementalAmount" />
									元
								</div>
							</td>
						</tr>
						<tr>
							<td>应退押金</td>
							<td colspan="2">{{ dueDepositRefund }} 元</td>
							<td colspan="2">实退押金</td>
							<td>{{ dueDepositRefund > 0 ? dueDepositRefund : 0 }} 元</td>
						</tr>
					</table>

					<div v-if="model.unpaidBillList && model.unpaidBillList.length">
						<div style="background: #f2f2f2; line-height: 30px; padding: 0 20px; margin: 10px 0">
							结算通过后，线下已收：
							<span style="color: #ed9f45">{{ model.offlinePaidRent }}</span>
							元，押金金额：
							<span style="color: #ed9f45">{{ paidDeposit }}</span>
							元 将会抵扣未支付租金账单，线下已收可抵扣：
							<span style="color: #e30f04">
								{{ offlineRemain }}
							</span>
							元，押金金额可抵扣：
							<span style="color: #e30f04">{{ depositRemain }}</span>
							元
						</div>
						<yi-table
							ref="yiTableRef"
							table-height="100%"
							:columns="columns"
							:data="model.unpaidBillList"
							autoWidth
						>
							<template #actualTradeAmount="{ row, value }">
								<p style="margin: 0 !important; line-height: 16px">{{ value }}</p>
								<span style="font-size: 12px" v-if="row.deductionAmount">
									减免抵扣{{ row.deductionAmount }}
								</span>
								<span v-if="row.deductionAmount && row.deductionDays">,</span>
								<span style="font-size: 12px" v-if="row.deductionDays">
									租期未满减{{ row.deductionDays }}天
								</span>
							</template>
							<template #depositDeductionAmount="{ row, index }">
								<el-input
									placeholder="请输入押金抵扣"
									v-model="row.depositDeductionAmount"
									@change="val => onDeductionChange(val, index)"
									@blur="onBlur(row.depositDeductionAmount)"
								/>
							</template>
						</yi-table>
					</div>

					<div v-if="model.supplementalRentBills">
						<div style="background: #f2f2f2; line-height: 30px; padding: 0 20px; margin: 10px 0">
							押金用于抵扣应补租金
							<span style="color: #ed9f45">
								{{ depositRemain }}
							</span>
							元，抵扣完剩余押金 ：
							<span style="color: #e30f04">{{ supplementalRemain.toFixed(2) }}</span>
							元
						</div>
						<yi-table
							ref="yiTableRef"
							table-height="100%"
							:columns="supplementColumn"
							:data="[model.supplementalRentBills]"
							autoWidth
						>
							<template #depositDeductionAmount="{ row }">
								<el-input
									placeholder="请输入押金抵扣"
									v-model="row.depositDeductionAmount"
									@change="val => onSupplementalChange(val)"
									@blur="onBlur(row.depositDeductionAmount)"
								/>
							</template>
						</yi-table>
					</div>

					<el-form-item
						label="退车结算说明"
						prop="settlementRemark"
						:rules="[{ required: true, message: '请填写退车结算说明' }]"
						style="width: 925px; margin-top: 18px"
					>
						<el-input
							v-model="model.settlementRemark"
							type="textarea"
							:autosize="{ minRows: 4 }"
							:maxlength="200"
							show-word-limit
						/>
					</el-form-item>
					<el-form-item label="附件" prop="settlementAnnexesArr">
						<span>
							({{ model.settlementAnnexesArr ? model.settlementAnnexesArr.length : 0 }}/15)
						</span>
						<yi-file-uploader
							v-model="model.settlementAnnexesArr"
							type="file"
							source-type="image"
							product-type="renrenjia"
							accept=".jpg,.jpeg,.png,.xlsx,.xls,.pdf,.zip,.rar,.pptx,.docx"
							:max="15"
							:uploader-size="15"
						/>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit" :loading="loading">提交退车结算</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import convert from 'dom-to-image'
import { defineExpose, inject, defineEmits, computed, ref, watch } from 'vue'
import { useState, useModal, useFetch, useDict } from '@/utils/hooks.js'
import { reviewSettlementBill, querySettlementBill, getBillByContractId } from '@/api/driver.js'
import request from '@/http'
import { dataURLtoFile } from '@/utils/util'
import axios from 'axios'
import { getStore } from '@/utils/store'

const $message = inject('$message')
const emit = defineEmits(['on-refresh', 'on-detail'])
const remainAmount = ref(0)
const supplementalRemain = ref(0)
const { visible, setVisible } = useModal()
const [model, setModel] = useState({
	marketingPlan: Array.from({ length: 4 }, _ => ({ amount: 0 })),
})
const [dicts, setDicts] = useState({})
const [loading, setLoading] = useState(false)
const [tableLoading, setTableLoading] = useState(false)
const { fetchData } = useFetch()

const columns = [
	{
		prop: 'tradeBillName',
		label: '账单名称',
	},
	{
		prop: 'statementMonth',
		label: '账单月',
	},
	{
		prop: 'actualTradeAmount',
		label: '应收额',
	},
	{
		prop: 'actualAmount',
		label: '已实收额',
	},
	{
		prop: 'dueAmount',
		label: '欠租金额',
	},
	{
		prop: 'depositDeductionAmount',
		label: '押金抵扣',
	},
]

const supplementColumn = [
	{
		prop: 'tradeBillName',
		label: '账单名称',
	},
	{
		prop: 'statementMonth',
		label: '账单月',
	},
	{
		prop: 'tradeAmount',
		label: '应收额',
	},
	{
		prop: 'depositDeductionAmount',
		label: '押金抵扣',
	},
]

const defaultValList = {
	offlinePaidRent: '线下已收+平台锁定',
	accidentDepreciationFee: '事故加速折旧费',
	parkingFee: '停车费/换电费',
	absoluteDeductibleFee: '绝对免赔',
	vehicleRecyclingFee: '收车费',
	vehicleInsuranceClaimCount: '出险次数',
	accidentFee: '事故费用',
	violateRegulationDisposeFee: '违章费用',
	interiorCleaningFee: '内饰清洗费',
	gpsFee: '车钥匙+GPS',
	vehicleDamageFee: '车损',
	operationLicenseFee: '营运证费用',
	depreciationFee: '加速折旧费',
	drivingLicenseFee: '行驶证费用',
	supplementalAmount: '退车补款',
	actualRentReceivable: '实际应收租金',
}
watch(
	() => model.value.offlinePaidRent,
	(val, oldVal) => {
		const { actualRentReceived, actualRentReceivable } = model.value
		const num =
			(!isNaN(actualRentReceivable) ? actualRentReceivable : 0) * 1 -
			(actualRentReceived || 0) * 1 -
			val
		const nullish = ['', undefined, null]
		if (!nullish.includes(val) && !nullish.includes(oldVal)) {
			if (num < 0) {
				model.value.offlinePaidRent = oldVal
				return $message.error('应补租金不能小于0')
			}
		}
	},
)
const supplementalRent = computed(() => {
	const { actualRentReceived, actualRentReceivable, offlinePaidRent } = model.value
	const res =
		(!isNaN(actualRentReceivable) ? actualRentReceivable : 0) * 1 -
		(actualRentReceived || 0) * 1 -
		(!isNaN(offlinePaidRent) ? offlinePaidRent : 0) * 1
	return res.toFixed(2) * 1
})

const paidDeposit = computed(() => {
	const { actualDeposit } = model.value
	return actualDeposit * 1
})
const 小计1 = computed(() => {
	const sum = (supplementalRent.value || 0) * 1 + (model.value.breachPenalty || 0) * 1
	return sum.toFixed(2) * 1
})
const 小计2 = computed(() => {
	const keys = [
		'accidentDepreciationFee',
		'absoluteDeductibleFee',
		'accidentFee',
		'interiorCleaningFee',
		'vehicleDamageFee',
		'depreciationFee',
	]
	const sum = keys.reduce((acc, key) => {
		const cur = model.value[key] * 1
		if (!isNaN(cur)) acc += cur
		return acc
	}, 0)
	return sum.toFixed(2) * 1
})
const depositRemain = computed(() => {
	const num = model.value.offlinePaidRent * 1 - calcSum()
	return num > 0
		? calcSum() == 0
			? (paidDeposit.value + num).toFixed(2)
			: paidDeposit.value
		: (paidDeposit.value + num).toFixed(2)
})
const offlineRemain = computed(() => {
	return model.value.offlinePaidRent * 1 - calcSum() * 1 <= 0
		? 0
		: (model.value.offlinePaidRent * 1 - calcSum() * 1).toFixed(2)
})
const 小计3 = computed(() => {
	const keys = [
		'parkingFee',
		'vehicleRecyclingFee',
		'violateRegulationDisposeFee',
		'gpsFee',
		'operationLicenseFee',
		'drivingLicenseFee',
	]
	const sum = keys.reduce((acc, key) => {
		const cur = model.value[key] * 1
		if (!isNaN(cur)) acc += cur
		return acc
	}, 0)
	return sum.toFixed(2) * 1
})

const totalFee = computed(() => {
	const sum = 小计1.value + 小计2.value + 小计3.value
	return sum.toFixed(2) * 1
})

const marketingPlanAmount = computed(() => {
	const { marketingPlan = [{}] } = model.value
	const total = marketingPlan.reduce((acc, cur) => {
		acc += !isNaN(cur.amount) ? cur.amount * 1 : 0
		return acc
	}, 0)
	return total.toFixed(2)
})
const dueDepositRefund = computed(() => {
	const { actualDeposit, supplementalAmount } = model.value
	const res =
		actualDeposit * 1 + supplementalAmount * 1 + marketingPlanAmount.value * 1 - totalFee.value * 1
	return res.toFixed(2)
})
const settleNameComp = computed(() => {
	const { companyName } = model.value
	return `${companyName || ''}退车结算单`
})

function open(id) {
	getDict()
	getDetail(id)
	setVisible(true)
}
async function onActualRentChange(val) {
	if (isNaN(val)) return $message.error('实际应收租金格式有误')
	const { err, data } = await fetchData(() =>
		getBillByContractId(model.value.orderId, {
			returnCarDate: model.value.returnCarDate,
			actualRentReceivable: val,
		}),
	)
	if (err) return $message.error(err.message)
	console.log('结算单数据', data)
	const {
		leaseMonth,
		actualRentReceivable,
		leaseDays,
		actualRentReceived,
		supplementalRent,
		totalRentFee,
		breachPenalty,
		companyName,
	} = data
	setModel({
		...model.value,
		leaseMonth,
		actualRentReceivable,
		leaseDays,
		actualRentReceived,
		supplementalRent,
		totalRentFee,
		breachPenalty,
		companyName,
	})
}
function onClose() {
	setVisible(false)
	setModel({ marketingPlan: Array.from({ length: 4 }, _ => ({ amount: 0 })) })
}
async function getDict() {
	const dict = await useDict('terminate_contract_type')
	setDicts(dict)
}
function mapDict(key, value) {
	const e = dicts.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
function onDetail() {
	emit('on-detail', model.value.stockInId || ' ')
}
function onBlur(val) {
	const reg = /^\d+(?:\.\d{1,2})?$/
	if (!reg.test(val)) {
		const msg = '押金抵扣格式有误'
		$message.error(msg)
	}
}
const calcSum = () => {
	const { unpaidBillList } = model.value
	if (!unpaidBillList) return 0
	const total = unpaidBillList.reduce((acc, cur) => {
		if (!isNaN(cur.depositDeductionAmount)) {
			acc += cur.depositDeductionAmount * 1
		}
		return acc
	}, 0)
	return total
}
function onDeductionChange(val, index) {
	const { unpaidBillList } = model.value
	if (unpaidBillList[index] && val > unpaidBillList[index].dueAmount) {
		$message.error('押金金额不允许大于欠租金额')
		const remain =
			model.value.offlinePaidRent * 1 +
			paidDeposit.value * 1 -
			unpaidBillList.reduce((acc, cur, i) => {
				if (!isNaN(cur.depositDeductionAmount) && i !== index) {
					acc += cur.depositDeductionAmount * 1
				}
				return acc
			}, 0)
		unpaidBillList[index].depositDeductionAmount =
			unpaidBillList[index].dueAmount > remain ? remain : unpaidBillList[index].dueAmount
		const res = model.value.offlinePaidRent * 1 + paidDeposit.value * 1 - calcSum()
		remainAmount.value = res.toFixed(2)
		onSupRemainChange()
		return
	}
	const total = calcSum()
	const res = model.value.offlinePaidRent * 1 + paidDeposit.value * 1 - total * 1
	if (res < 0) {
		$message.error('剩余抵扣金额已不足够抵扣')
		const remain =
			model.value.offlinePaidRent * 1 +
			paidDeposit.value * 1 -
			unpaidBillList.reduce((acc, cur, i) => {
				if (!isNaN(cur.depositDeductionAmount) && i !== index) {
					acc += cur.depositDeductionAmount * 1
				}
				return acc
			}, 0)
		unpaidBillList[index].depositDeductionAmount =
			unpaidBillList[index].dueAmount > remain ? remain : unpaidBillList[index].dueAmount
		remainAmount.value = model.value.offlinePaidRent * 1 + paidDeposit.value * 1 - calcSum()
		onSupRemainChange()
		return
	}
	remainAmount.value = res > 0 ? res.toFixed(2) : 0
	onSupRemainChange()
}
function onSupRemainChange(hasRelation = true) {
	const num = depositRemain.value * 1 - model.value.supplementalRentBills.depositDeductionAmount * 1
	if (num > 0) {
		supplementalRemain.value = num
		if (hasRelation) {
			const remain = depositRemain.value * 1 - model.value.supplementalRentBills.tradeAmount * 1
			model.value.supplementalRentBills.depositDeductionAmount =
				remain > 0 ? model.value.supplementalRentBills.tradeAmount : depositRemain.value
			supplementalRemain.value = remain > 0 ? remain : 0
		}
	} else {
		supplementalRemain.value = 0
		model.value.supplementalRentBills.depositDeductionAmount = depositRemain.value * 1
	}
}
function onSupplementalChange(val) {
	if (val > model.value.supplementalRentBills.tradeAmount) {
		$message.error('剩余抵扣金额已不足够抵扣')
	}
	if (val > remainAmount.value) {
		$message.error('剩余抵扣金额已不足够抵扣')
	}
	onSupRemainChange(false)
}
function onSumChange() {
	if (model.value.supplementalRentBills) {
		model.value.supplementalRentBills.tradeAmount = supplementalRent
	}
	if (model.value.unpaidBillList && model.value.unpaidBillList.length) {
		onRemainChange()
		onDeductionChange()
	}
	if (model.value.supplementalRentBills && !model.value.unpaidBillList) {
		remainAmount.value = model.value.offlinePaidRent * 1 + paidDeposit.value * 1
	}
	onSupRemainChange()
}
function onRemainChange() {
	let sum = (model.value.offlinePaidRent || 0) * 1 + paidDeposit.value * 1
	if (model.value.unpaidBillList) {
		const converted = model.value.unpaidBillList.map(item => {
			const depositDeductionAmount = sum - item.dueAmount > 0 ? item.dueAmount : sum
			if (sum - item.dueAmount > 0) sum -= item.dueAmount
			else sum = 0
			return {
				...item,
				depositDeductionAmount,
			}
		})
		console.log('sum', converted)
		setModel({ ...model.value, unpaidBillList: converted })
	}
}
async function getDetail(id) {
	setTableLoading(true)
	const { err, data } = await fetchData(querySettlementBill, id)
	setTableLoading(false)
	if (err) return $message.error(err.message)
	console.log('结算单数据', data)
	const { marketingPlan = [] } = data
	const len = marketingPlan ? marketingPlan.length : 0
	const length = 4 - len
	setModel({
		...data,
		marketingPlan: (data.marketingPlan || []).concat(Array.from({ length }, _ => ({}))),
	})
}
async function checkParams() {
	const nullish = [undefined, null, '']
	return new Promise((res, rej) => {
		console.log('model.value', model.value)
		const { marketingPlan, actualRentReceivable, expirationRentReceivable } = model.value
		const { unpaidBillList } = model.value
		if (nullish.includes(model.value.certificateCondition)) {
			const msg = '行驶证-营运证' + '不能为空'
			$message.error(msg)
			return rej(msg)
		}

		const emptyKey = Object.keys(defaultValList).find(key => nullish.includes(model.value[key]))
		if (emptyKey) {
			const msg = defaultValList[emptyKey] + '不能为空'
			$message.error(msg)
			return rej(msg)
		}
		if (actualRentReceivable * 1 < 0) {
			const msg = '实际应收租金必须大于0'
			$message.error(msg)
			return rej(msg)
		}
		if (actualRentReceivable * 1 > expirationRentReceivable * 1) {
			const msg = '实际应收租金必须小于期满应收租金'
			$message.error(msg)
			return rej(msg)
		}
		const reg = /^\d+(?:\.\d{1,2})?$/
		const invalidKey = Object.keys(defaultValList).find(key => !reg.test(model.value[key]))
		if (invalidKey) {
			const msg = defaultValList[invalidKey] + '格式有误'
			$message.error(msg)
			return rej(msg)
		}

		const invalid = marketingPlan.some(item => item.content && nullish.includes(item.amount))
		if (invalid) {
			const msg = '营销方案折算现金不能为空'
			$message.error(msg)
			return rej(msg)
		}
		const invalidAmount = marketingPlan.some(item => item.content && !reg.test(item.amount))
		if (invalidAmount) {
			const msg = '营销方案折算现金格式有误'
			$message.error(msg)
			return rej(msg)
		}
		if (unpaidBillList && Array.isArray(unpaidBillList) && unpaidBillList.length) {
			const empty = unpaidBillList.some(item =>
				[null, undefined, ''].includes(item.depositDeductionAmount),
			)
			if (empty) {
				const msg = '请输入抵扣金额'
				$message.error(msg)
				return rej(msg)
			}
			const invalidFormat = unpaidBillList.some(item => !reg.test(item.depositDeductionAmount))
			if (invalidFormat) {
				const msg = '押金抵扣格式有误'
				$message.error(msg)
				return rej(msg)
			}
			const invalidAmount = unpaidBillList.some(
				item => item.depositDeductionAmount * 1 > item.dueAmount * 1,
			)
			if (invalidAmount) {
				const msg = '押金抵扣不允许大于欠租金额'
				$message.error(msg)
				return rej(msg)
			}
		}
		res()
	})
}
async function onSubmit() {
	await checkParams()
	const { marketingPlan: plan } = model.value
	const marketingPlan = plan.filter(item => item.content)
	const canvas = document.querySelector('#print')
	let url = ''
	setLoading(true)
	convert.toJpeg(canvas, { bgcolor: '#fff' }).then(curUrl => {
		console.log('url', url)
		url = curUrl
		const QINIU_UPLOAD_URL = {
			'oss-test-vsc-master.renrenjia.com.cn': 'https://up-z2.qiniup.com',
			'oss-prod-vsc-master.renrenjia.com.cn': 'https://up-z2.qiniup.com',
			'yiautos-business-oss-1.yiautos.com': 'https://up-z2.qiniup.com',
			'yiautos-business-oss-2.yiautos.com': 'https://up.qiniup.com', // 备用上传地址
		}
		const isProd = process.env.VUE_APP_CONFIG_ENV === 'prod'
		const qiniuCdnPrefix = isProd
			? 'oss-prod-vsc-master.renrenjia.com.cn'
			: 'oss-test-vsc-master.renrenjia.com.cn'
		const bucket_name = isProd ? 'oss-prod-vsc-master' : 'oss-test-vsc-master'
		request({
			url: 'suf4-system-service/oss/getQiniuToken',
			method: 'GET',
			params: {
				bucket_name,
			},
		})
			.then(async tokenRes => {
				if (tokenRes.code === 200) {
					console.log('key', tokenRes.data)
					const str = Math.random().toString(36).substr(2, 8)
					const tenantId = getStore({ name: 'tenantId' }) || '000000'
					const uploadKey = `${tenantId}/renrenjia/image/${dayjs().format('YYYYMMDD')}/${str}.jpg`
					const file = dataURLtoFile(url, str)
					const param = new FormData()
					param.append('token', tokenRes.data)
					param.append('key', uploadKey)
					param.append('file', file)
					const config = {
						headers: { 'Content-Type': 'multipart/form-data' },
					}
					const uploadResult = await axios.post(QINIU_UPLOAD_URL[qiniuCdnPrefix], param, config)
					if (uploadResult.status !== 200) {
						return $message.error('上传结算单失败!请稍后再试')
					}
					const settlementBillPicture = `https://${qiniuCdnPrefix}/${uploadResult.data.key}`
					const params = {
						...model.value,
						settlementBillPicture,
						marketingPlan,
						billId: model.value.id,
					}
					if (model.value.supplementalRentBills) {
						params.supplementalRentDepositDeductionAmount =
							model.value.supplementalRentBills.depositDeductionAmount
					}
					const { err } = await fetchData(reviewSettlementBill, params)
					setLoading(false)
					if (err) return $message.error(err.message)
					$message.success('提交复核结算成功')
					emit('on-refresh')
					onClose()
				} else {
					setLoading(false)
					throw new Error(tokenRes.msg)
				}
			})
			.catch(e => {
				$message.error(e)
				setLoading(false)
			})
	})
}

defineExpose({ open, onClose })
</script>

<style lang="less" scoped>
.header {
	.flexible(row, center, space-between);
	margin-bottom: 12px;
	color: rgba(0, 0, 0, 0.84);
	font-weight: 500;
	.click {
		color: #409eff;
		cursor: pointer;
	}
}
.flex-row {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.mr6 {
	margin-right: 6px;
}
table {
	border-collapse: collapse;
}
th,
td {
	border: 1px solid rgba(0, 0, 0, 0.84);
	text-align: center;
	min-width: 120px;
	height: 36px;
}
tr {
	text-align: center;
}
#print {
	padding: 0 10px 30px 10px;
}
</style>
