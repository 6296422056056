export const columns = [
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		width: '140',
	},
	{
		prop: 'lessee',
		label: '司机姓名',
		width: '140',
	},
	{
		prop: 'terminateContractType',
		label: '退车类型',
		width: '140',
	},
	{
		prop: 'status',
		label: '结算状态',
		width: '140',
	},
	{
		prop: 'contractEndDate',
		label: '合同结束日期',
		width: '200',
	},
	{
		prop: 'returnCarDate',
		label: '实际还车日期',
		width: '200',
	},
	{
		prop: 'leaseMonth',
		label: '实际使用月份',
		width: '140',
	},
	{
		prop: 'leaseDays',
		label: '不满一个月天数',
		width: '140',
	},
	{
		prop: 'totalFee',
		label: '费用合计',
		width: '140',
	},

	{
		prop: 'dueDepositRefund',
		label: '应退押金',
		width: '140',
	},
	{
		prop: 'depositRefund',
		label: '实退押金',
		width: '140',
	},
	{
		prop: 'maintenanceFee',
		label: '整备收入',
		width: '140',
	},
	{
		prop: 'collectAndPayAmount',
		label: '代收代付',
		width: '140',
	},

	{
		prop: 'accidentCosts',
		label: '事故费用',
		width: '140',
	},
	{
		prop: 'breachPenalty',
		label: '违约金',
		width: '140',
	},

	{
		prop: 'createTime',
		label: '结算发起时间',
		width: '280',
	},
	{
		prop: 'reviewFinishTime',
		label: '复核完成时间',
		width: '140',
	},
	{
		prop: 'companyName',
		label: '所属公司',
		width: '380',
	},

	{
		prop: 'operation',
		label: '操作',
		fixed: 'right',
		width: '120px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			width: 358,
		},
		{
			placeholder: '请选择结算状态',
			label: '结算状态',
			attr: 'status',
			type: 'select',
			dictKey: 'settlement_status',
		},
		{
			placeholder: '请选择实际还车日期',
			label: '实际还车日期',
			attr: 'returnCarDateList',
			type: 'date',
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyIds',
			type: 'multi-select',
			width: 320,
			options: [],
		},
		{
			placeholder: '请选择司服伙伴',
			label: '司服伙伴',
			attr: 'driverSupportId',
			type: 'select',
			options: [],
		},

		{
			placeholder: '请选择退车类型',
			label: '退车类型',
			attr: 'terminateContractType',
			type: 'select',
			options: [],
			dictKey: 'terminate_contract_type',
		},
		{
			placeholder: '请选择结算发起时间',
			label: '结算发起时间',
			attr: 'createTimeList',
			type: 'date',
		},
		{
			placeholder: '请选择复核完成时间',
			label: '复核完成时间',
			attr: 'reviewFinishTimeList',
			type: 'date',
		},
	],
}

export const form = [
	{
		prop: 'driverNo',
		label: '司机ID',
		component: 'el-input',
		attrs: { placeholder: '请输入司机ID' },
	},
	{
		prop: 'companyId',
		label: '所属公司',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择所属公司' },
	},
	{
		prop: 'name',
		label: '司机姓名',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机姓名' },
	},
	{
		prop: 'mobile',
		label: '手机号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机手机号' },
	},
	{
		prop: 'idCard',
		label: '身份证号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证号' },
	},
	{
		prop: 'sex',
		label: '性别',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择性别' },
	},
	{
		prop: 'idCardAddress',
		label: '身份证地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证地址' },
	},
	{
		prop: 'birthday',
		label: '出生日期',
		required: true,
		custom: true,
	},
	{
		prop: 'presentAddress',
		label: '现居住地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入现居住地址' },
	},
	{
		prop: 'contactPersonName',
		label: '紧急联系人',
		component: 'el-select',
		options: [],
	},
	{
		prop: 'contactPersonMobile',
		label: '联系人手机',
		component: 'el-input',
		attrs: { placeholder: '请输入联系人手机' },
	},
	{
		prop: 'drivingLicenseDate',
		label: '驾驶证日期',
		custom: true,
	},
]

export const uploadForm = [
	{
		prop: 'idCardPic',
		required: true,
		label: '身份证',
		custom: true,
	},
	{
		prop: 'drivingLicensePic',
		label: '驾驶证',
		component: 'yi-file-uploader',
		required: true,
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传驾驶证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'certificateProfessionalPic',
		label: '从业资格证',
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传从业资格证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
]
